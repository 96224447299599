import { FC } from 'react';

import { TileBarWrapper, TopTaskBadge } from '@custom-components/TopTaskTileBar';
import { getIllustration, wrap } from '@sitecore/common';
import { TopTasksRendering } from '@sitecore/types/manual/TopTasks';
import { Box, Hidden, Stack, Text, TileBar } from '@sparky';
import { useMediaQuery } from '@sparky/hooks';
import { ChevronRightIcon } from '@sparky/icons';

const TopTasks: FC<TopTasksRendering> = ({ fields }) => {
  const isMobile = !useMediaQuery('md');
  if (!fields?.items || fields.items.length === 0) {
    return null;
  }

  return (
    <Box paddingTop="8">
      <TileBarWrapper>
        <TileBar direction={{ initial: 'column', md: 'row' }}>
          {fields.items.map((item, i) => {
            const Illustration = getIllustration(item.fields.illustration.value);
            return (
              <TileBar.Item
                linkValue={item.fields.link.value}
                linkType={item.fields.link.value.linktype}
                key={i}
                hoverContent={
                  <Text size="BodyXS" align="center">
                    {wrap(item.fields.subTitle)}
                  </Text>
                }>
                <Stack direction="row" alignY="center" alignX={{ initial: 'justify', md: 'center' }}>
                  <Stack
                    direction={{ initial: 'row', md: 'column' }}
                    gap={{ initial: '6', md: '3' }}
                    alignY="center"
                    alignX={{ initial: 'start', md: 'center' }}>
                    {!isMobile && item?.fields?.badgeText?.value ? (
                      <TopTaskBadge color="highemphasis">{item?.fields?.badgeText?.value}</TopTaskBadge>
                    ) : null}
                    <Illustration size={{ initial: 'small', md: 'medium' }} color="iconSecondary" />
                    <Text align={{ initial: 'left', md: 'center' }} size="BodyS" weight="bold">
                      {wrap(item.fields.title)}
                    </Text>
                  </Stack>
                  <Hidden above="md">
                    <Stack direction="row" gap="3" alignX="end">
                      {isMobile && item?.fields?.badgeText?.value ? (
                        <TopTaskBadge color="highemphasis">{item?.fields?.badgeText?.value}</TopTaskBadge>
                      ) : null}
                      <div>
                        <ChevronRightIcon />
                      </div>
                    </Stack>
                  </Hidden>
                </Stack>
              </TileBar.Item>
            );
          })}
        </TileBar>
      </TileBarWrapper>
    </Box>
  );
};

export default TopTasks;
